@import './carousel.css';

// @font-face {
//   font-family: 'ivoji icons';
//   src: url('../fonts/ivoji-icons.woff') format('woff');
// }

// @font-face {
//   font-family: 'PBB';
//   src: url('../fonts/ivoji.woff') format('woff');
// }

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1600px) {
    @content;
  }
}

// Your customize CSS
$footer: #142535;
$light-black: #151515;
$sub-white: #e0e0e0;
$top-red: #ba000e;
$red: #da0011;
$green: #23ce6b;
$sub-green: #22939a;
$blue: #0079c8;
$sub-blue: #0092ff;
$dark-grey: #3e505d;
$grey: #455a64;

$font-mobile-sm: 0.8rem;
$font-desktop-sm: 1.125rem;

$font-mobile-md: 1.125rem;
$font-desktop-md: 1.25rem;

$font-mobile-lg: 1.4rem;
$font-desktop-lg: 1.96rem;

$font-mobile-xl: 1.96rem;
$font-desktop-xl: 2.8rem;

$max-width: 1240px;

$breadcrumb-divider: '\f105';

/**
 * ------------------------------------------
 *  Responsive Grid Media Queries - 1280, 1024, 768, 480
 *   1280-1024   - desktop (default grid)
 *   1024-768    - tablet landscape
 *   768-480     - tablet 
 *   480-less    - phone landscape & smaller
 * --------------------------------------------
 */
@media all and (min-width: 1024px) and (max-width: 1280px) {
}

@media all and (min-width: 768px) and (max-width: 1024px) {
}

@media all and (min-width: 480px) and (max-width: 768px) {
}

@media all and (max-width: 480px) {
}

/* Portrait */
@media screen and (orientation: portrait) {
  /* Portrait styles here */
}

/* Landscape */
@media screen and (orientation: landscape) {
  /* Landscape styles here */
}

/* CSS for iPhone, iPad, and Retina Displays */

/* Non-Retina */
@media screen and (-webkit-max-device-pixel-ratio: 1) {
}

/* Retina */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
}

/* iPhone Portrait */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
}

/* iPhone Landscape */
@media screen and (max-device-width: 480px) and (orientation: landscape) {
}

/* iPad Portrait */
@media screen and (min-device-width: 481px) and (orientation: portrait) {
}

/* iPad Landscape */
@media screen and (min-device-width: 481px) and (orientation: landscape) {
}

@media (min-width: 1366px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: $max-width;
  }
  .container {
    @include for-desktop-up {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .container-banner {
    max-width: 1280px;
  }
}

body {
  font-family: 'UniversNext', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.94;
  font-weight: 400;
  font-size: $font-desktop-sm;

  @include for-phone-only {
    padding-top: initial;
    padding-bottom: initial;
  }
}

.h2,
h2 {
  font-size: 2.75rem;
  font-weight: 300;
  @include for-phone-only() {
    font-size: 1.75rem;
    letter-spacing: -0.05rem;
  }
  @include for-tablet-portrait-up() {
    font-size: xx-large;
  }
  @include for-tablet-landscape-up() {
    font-size: $font-desktop-xl;
  }
}

.h3,
h3 {
  font-size: 2.5rem;
  font-weight: 200;
}

.fix-link-position {
  display: inherit;
  @include for-tablet-portrait-up() {
    display: none;
  }
  @include for-tablet-landscape-up() {
    display: inherit;
  }
}

.link-with-right-chevron {
  line-height: 1.8;
  font-weight: 500;
}

.hidden {
  display: none !important;
}

.white {
  color: white;
}

.big-title {
  font-weight: 300;
  font-size: $font-desktop-xl;

  @include for-phone-only() {
    font-size: $font-mobile-xl;
    line-height: normal;
  }
}

.font-color-white {
  color: white;
}

.label-title {
  text-align: center;
  font-size: 3.2rem;
  font-weight: 200;
  margin-bottom: 0.5em;

  @include for-phone-only() {
    font-size: 2.2rem;
  }
}

.modal-dialog {
  @include for-desktop-up() {
    font-size: 1.4rem;
    font-weight: 300;
    .modal-title {
      font-size: 2.4rem;
      font-weight: 300;
    }
  }
}

.card-text {
  font-size: $font-desktop-sm;
}

.wd-75 {
  @include for-desktop-up {
    width: $max-width;
  }
}

.navbar {
  @include for-desktop-up {
    height: 72px;
    font-size: 1.4rem;
    padding: 1.5rem 0;
  }

  .logo {
    @include for-desktop-up {
      top: 13px;
    }

    img {
      height: 56px;
      margin-left: 8vw;

      @include for-big-desktop-up {
        margin-left: 14vw;
      }
    }
  }

  a.logo {
    position: initial;
    @include for-desktop-up() {
      position: absolute;
    }
  }

  .nav-link {
    line-height: 1.45;
    margin-right: 0.6rem;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
    @include for-desktop-up() {
      margin-right: 2rem;
    }
  }

  ul {
    .active {
      position: relative;
    }

    .active:after {
      content: '';
      position: absolute;
      margin-bottom: -12px;
      height: 4px;
      background-color: $sub-green;
      width: 0;
      bottom: 0;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
      width: calc(100% - 2rem);
      @include for-phone-only() {
        margin-bottom: 0;
      }
      @include for-tablet-portrait-up() {
        margin-bottom: -12px;
      }
    }

    li {
      position: relative;
    }

    li:after {
      content: '';
      position: absolute;
      margin-bottom: -12px;
      height: 4px;
      background-color: $green;
      width: 0;
      bottom: 0;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
    }

    li:hover:after {
      width: calc(100% - 2rem);
    }
  }
}

.navbar-light {
  .navbar-nav {
    .nav-link {
      color: black;
    }

    .nav-link:hover {
      color: rgba(0, 0, 0, 1);
    }

    .nav-link:focus {
      color: rgba(0, 0, 0, 1);
    }
    .nav-item {
      @include for-phone-only() {
        margin-top: 16px;
      }
    }
  }

  ul:hover > li {
    opacity: 0.3;
  }

  ul:hover li:hover {
    opacity: 1;
  }
}

nav[aria-label='breadcrumb'] {
  background-color: #000;

  .breadcrumb-item + .breadcrumb-item::before {
    font-family: 'ivoji icons' !important;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '';
    font-size: 0.8em;
    padding: 0 18px;
    color: #ccc;
  }

  ol:hover > li {
    opacity: 0.3;
  }

  ol:hover li:hover {
    opacity: 1;
  }

  .breadcrumb {
    height: 4em;
    padding: 0;

    .breadcrumb-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    @include for-desktop-up {
      background-color: inherit;
      max-width: $max-width;
      margin: 0 auto;
    }
    @include for-phone-only() {
      height: 0;
    }
  }
}

.banner {
  @include for-phone-only {
    .stick-bottom {
      bottom: 0;
      position: absolute;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      // background-image:
      // linear-gradient(
      //   $grey, black
      // );
    }
  }

  p {
    font-size: $font-desktop-md;
  }

  ul {
    list-style-type: none;

    @include for-desktop-up {
      padding-left: 0;
    }
  }
}

.tkb90-desktop hidden {
  @include for-phone-only() {
    display: none;
  }
}

.tkb90-mobile hidden {
  display: none;
  @include for-phone-only() {
    display: block;
  }
}

.bg-homepage {
  background: url(../assets/images/bg-banner7.jpg);
  background-color: #fff;
  background-size: cover;
  background-position: 50%;
  height: 580px;

  @include for-phone-only() {
    height: 600px;
  }
  @include for-big-desktop-up {
    height: 650px;
  }
}

.bg-homepage-slim {
  background: url(../assets/images/bg-banner7.jpg);
  background-color: #fff;
  background-size: cover;
  background-position: 50%;
  height: 360px;
  position: relative;

  @include for-big-desktop-up {
    height: 400px;
  }
}

.bg-homepage-partner {
  background: url(../assets/images/bg-banner10.jpg);
  background-color: #fff;
  background-size: cover;
  background-position: 50%;
  height: 360px;
  position: relative;

  @include for-big-desktop-up {
    height: 400px;
  }
}

.bg-homepage-teams {
  background: url(../assets/images/bg-banner12.jpg);
  background-color: #fff;
  background-size: cover;
  background-position: 50%;
  height: 360px;
  position: relative;

  @include for-big-desktop-up {
    height: 400px;
  }
}

.bg-homepage1 {
  background: url(../assets/images/bg-homepage.jpg);
  background-color: #fff;
  background-size: cover;
  background-position: 50%;
  height: 580px;

  @include for-big-desktop-up {
    height: 650px;
  }
}

.bg-homepage2 {
  background: url(../assets/images/bg-banner8.jpg);
  background-color: #fff;
  background-size: cover;
  background-position: 50%;
  height: 580px;

  @include for-big-desktop-up {
    height: 650px;
  }
}

.bg-homepage3 {
  background: url(../assets/images/bg-banner9.jpg);
  background-color: #fff;
  background-size: cover;
  background-position: 50%;
  height: 580px;

  @include for-big-desktop-up {
    height: 650px;
  }
}

.bg-homepage4 {
  background: url(../assets/images/bg-banner11.jpg);
  background-color: #fff;
  background-size: cover;
  background-position: 50%;
  height: 580px;

  @include for-big-desktop-up {
    height: 650px;
  }
}

.d-inline-flex {
  padding: 0;
}

.banner-belt {
  @include for-tablet-portrait-up {
    font-size: 0.6rem;
    flex-wrap: inherit;
  }

  h4 {
    font-weight: 200;
    @include for-phone-only() {
      font-size: 1.3rem;
    }

    @include for-tablet-portrait-up {
      font-size: 0.8rem;
    }

    @include for-tablet-landscape-up {
      font-size: 2rem;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  @include for-desktop-up {
    font-size: 1rem;
  }
}

main {
  @include for-desktop-up {
    margin-top: 24px;
  }
}

.featurette-divider {
  @include for-phone-only {
    margin: 3rem 0;
  }
}

.banner,
.bg-homepage,
.bg-homepage1,
.bg-homepage2,
.bg-homepage3,
.bg-homepage4 {
  position: relative;
}

.container {
  &.middle {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) translateY(45px);
    transform: translateX(-50%) translateY(-50%) translateY(45px);
    width: 100%;

    .title-phone-top {
      @include for-phone-only {
        position: relative;
        top: 4em;
      }
    }

    @include for-phone-only {
      position: initial;
      top: initial;
      left: initial;
      -webkit-transform: initial;
      transform: initial;
      width: 100%;
    }
  }

  &.middle-1 {
    position: absolute;
    top: 10%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) translateY(45px);
    transform: translateX(-50%) translateY(-50%) translateY(45px);
    width: 100%;

    .title-phone-top {
      @include for-phone-only {
        position: relative;
        top: 4em;
      }
    }

    @include for-phone-only {
      position: initial;
      top: initial;
      left: initial;
      -webkit-transform: initial;
      transform: initial;
      width: 100%;
    }
  }

  &.middle-2 {
    position: absolute;
    top: 20%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) translateY(45px);
    transform: translateX(-50%) translateY(-50%) translateY(45px);
    width: 100%;

    .title-phone-top {
      @include for-phone-only {
        position: relative;
        top: 4em;
      }
    }

    @include for-phone-only {
      position: initial;
      top: initial;
      left: initial;
      -webkit-transform: initial;
      transform: initial;
      width: 100%;
    }
  }

  &.middle-3 {
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) translateY(45px);
    transform: translateX(-50%) translateY(-50%) translateY(45px);
    width: 100%;

    .title-phone-top {
      @include for-phone-only {
        position: relative;
        top: 4em;
      }
    }

    @include for-phone-only {
      position: initial;
      top: initial;
      left: initial;
      -webkit-transform: initial;
      transform: initial;
      width: 100%;
    }
  }

  &.middle-4 {
    position: absolute;
    top: 40%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) translateY(45px);
    transform: translateX(-50%) translateY(-50%) translateY(45px);
    width: 100%;

    .title-phone-top {
      @include for-phone-only {
        position: relative;
        top: 4em;
      }
    }

    @include for-phone-only {
      position: initial;
      top: initial;
      left: initial;
      -webkit-transform: initial;
      transform: initial;
      width: 100%;
    }
  }

  &.middle-5 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) translateY(45px);
    transform: translateX(-50%) translateY(-50%) translateY(45px);
    width: 100%;

    .title-phone-top {
      @include for-phone-only {
        position: relative;
        top: 4em;
      }
    }

    @include for-phone-only {
      position: initial;
      top: initial;
      left: initial;
      -webkit-transform: initial;
      transform: initial;
      width: 100%;
    }
  }
}

.bigscreen {
  height: 580px;
  margin-bottom: 30rem;

  @include for-big-desktop-up {
    height: 650px;
  }

  .card {
    border: none;
  }

  .row {
    margin-right: 0px;
    margin-left: 0px;
  }

  .promobox-with-photo {
    background: none;
    text-align: center;
    color: white;
    padding: 72px 0 72px;
    height: 580px;

    @include for-big-desktop-up {
      height: 650px;
    }

    img {
      object-fit: cover;
      bottom: 0;
      position: absolute;
      width: 100%;
    }
  }

  .promobox-with-text {
    background: #647987;
    text-align: left;
    color: white;
    height: 390px;

    .promobox__content {
      .card-title {
        font-size: 3rem;

        @include for-phone-only {
          font-size: 2rem;
        }
      }
    }

    .word-highlight {
      background: white;
      color: black;

      p {
        border-color: $red;
        border-style: solid;
        border-width: 0px 0px 0px 5px;
        padding-left: 2rem;
        font-size: 2rem;
      }
    }
  }

  .promobox {
    background: #647987;
    text-align: center;
    color: white;
    top: 300px;
    height: 390px;
    padding: 72px 0 72px;
  }
}

.promobox .background-light-gray .promobox__content {
  background-color: #f5f6f7;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 64px;
  padding-bottom: 60px;

  .h3 {
    margin-bottom: 36px;
  }
}

.font-weight-lighter {
  font-weight: lighter;
}

.font-size-larger {
  font-size: larger;
}

.font-size-x-larger {
  font-size: x-large;
}

.playstore {
  @include for-phone-only() {
    width: 100%;
  }
  a {
    img {
      @include for-phone-only() {
        width: 100%;
      }
    }
  }
}
.banner-box-container {
  margin-top: 80px;
  margin-bottom: 80px;
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 61px;
  padding-bottom: 71px;
  padding-left: 70px;
  padding-right: 70px;
  @include for-phone-only() {
    padding-left: 12px;
    padding-right: 12px;
  }
  @include for-desktop-up() {
    margin-top: 45px;
    margin-bottom: 80px;
  }
  @include for-big-desktop-up() {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  &.content {
    position: absolute;
    top: 200px;
    left: 50%;
    -webkit-transform: translateX(-78%) translateY(-50%) translateY(45px);
    transform: translateX(-78%) translateY(-50%) translateY(45px);
    width: 800px;
    height: 600px;
    @include for-tablet-portrait-up() {
      height: 100%;
      -webkit-transform: translateX(-50%) translateY(-50%) translateY(45px);
      transform: translateX(-50%) translateY(-50%) translateY(45px);
    }
    @include for-desktop-up() {
      height: 100%;
      -webkit-transform: translateX(-78%) translateY(-50%) translateY(45px);
      transform: translateX(-78%) translateY(-50%) translateY(45px);
    }
    .title-phone-top {
      @include for-phone-only {
        position: relative;
        top: 2em;
      }
    }

    @include for-phone-only {
      position: initial;
      top: initial;
      left: initial;
      -webkit-transform: initial;
      transform: initial;
      width: 100%;
    }
  }
}

.bg-lender-layout1 {
  background: url(../assets/images/bg-banner2.jpg);
  background-color: #fff;
  background-size: cover;
  background-position: 50%;
}

.bg-borrower-layout1 {
  background: url(../assets/images/bg-banner6.jpg);
  background-color: #fff;
  background-size: cover;
  background-position: 50%;
}

// .py-4.animated.animatedFadeInUp.fadeInUp.col-md-10.col-lg-6 {
//   background-color: black;
//   color: white;
// }

.bg-red {
  background: $red;
}

.bg-green {
  background: $sub-green;
}

.bg-glass-black {
  background-color: rgba(20, 20, 20, 0.7);
}

.text-tight {
  letter-spacing: -0.05rem;
}

.br-point {
  border-right: 1px solid $sub-white;

  @include for-phone-only {
    border-right: 0px;
    border-bottom: 1px solid $sub-white;
    padding: 1.5rem 0;
  }
}

.br-point:first-child {
  @include for-phone-only {
    padding-top: 1rem;
  }
}

.br-point:last-child {
  border-right: 1px solid $sub-white;

  @include for-phone-only {
    border-right: none;
    border-bottom: none;
    padding-bottom: 1rem;
  }

  @include for-tablet-portrait-up {
    border-right: none;
  }
}

.br-point-grey {
  border-right: 1px solid $grey;

  @include for-phone-only {
    border-right: 0px;
    border-bottom: 1px solid $grey;
    padding: 1.5rem 0;
  }
}

.br-point-grey:first-child {
  @include for-phone-only {
    padding-top: 1rem;
  }
}

.br-point-grey:last-child {
  border-right: 1px solid $sub-white;

  @include for-phone-only {
    border-right: none;
    border-bottom: none;
    padding-bottom: 1rem;
  }

  @include for-tablet-portrait-up {
    border-right: none;
  }
}

.card-columns {
  @include for-tablet-portrait-up() {
    column-count: 2;
  }
  .card {
    margin-bottom: 0.75rem;
  }
}

.card {
  border-radius: 0;
  border-style: none;

  .card-img-top {
    max-width: 640px;
    max-height: 480px;
    border-radius: 0;
  }

  .card-img-top-portrait {
    border-radius: 0;
    height: 768px !important;
    width: auto !important;
    object-fit: cover;
  }

  @include for-desktop-up {
    .promobox__content {
      padding-left: 70px;
      padding-right: 70px;
      padding-top: 64px;

      .card-title {
        font-weight: 200;
        line-height: 1.0769;
        color: inherit;
        margin-bottom: 36px;
      }

      .in-page-link {
        margin-top: 32px;
      }

      .btn {
        width: 8em;
        height: 4em;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @include for-desktop-up {
    .blogbox__content {
      background-color: #f5f6f7;
      padding-left: 70px;
      padding-right: 70px;
      padding-top: 64px;

      .card-title {
        font-weight: 200;
        line-height: 1.0769;
        color: inherit;
        margin-bottom: 36px;
      }

      .in-page-link {
        margin-top: 32px;
      }

      .btn {
        width: 8em;
        height: 4em;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.text-sub-white {
  color: $sub-white;
}

.sublayout {
  padding: 0px 0px 90px 0px;

  @include for-phone-only {
    padding: initial;
  }

  p {
    letter-spacing: 0.3px;
    line-height: 1.6;
    margin-bottom: 30px;
    font-size: $font-desktop-md;
    font-weight: 300;

    @include for-phone-only {
      letter-spacing: 0.2px;
      line-height: 1.5;
      font-size: $font-mobile-md;
      margin-bottom: 25px;
    }
  }

  ul {
    padding-left: 1.4rem;
    line-height: 2.8rem;
    font-size: $font-desktop-md;
    font-weight: 300;
    @include for-phone-only {
      font-size: $font-mobile-md;
    }
  }

  .stand-first {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.67;
    margin-top: -10px;
  }

  .content {
    position: relative;

    .image-container {
      background: url(../assets/images/bg-banner3.jpg);
      background-color: #fff;
      background-size: cover;
      background-position: 50%;
      height: 580px;

      @include for-big-desktop-up {
        height: 650px;
      }

      margin-left: calc((100vw - 1170px) / -2);
      margin-right: calc((100vw - 1170px) / -2);
      padding-left: calc((100vw - 1170px) / 2);
      padding-right: calc((100vw - 1170px) / 2);
    }

    .text-container {
      background: #647987;
      text-align: center;
      color: white;
      position: absolute;
      bottom: -90px;
      padding: 72px 0 72px;
    }
  }

  .fact-title {
    @include for-phone-only {
      font-size: 2.2rem;
    }

    @include for-tablet-portrait-up {
      font-size: 2rem;
    }

    @include for-desktop-up {
      font-size: 3.2rem;
    }
  }

  .fact-sub-title {
    @include for-phone-only {
      font-size: 1.6rem;
    }

    @include for-tablet-portrait-up {
      font-size: 1.2rem;
    }

    @include for-desktop-up {
      font-size: 1.4rem;
    }
  }
}

.container-fluid {
  max-width: $max-width;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.stockholder-row {
  .stockholder-wrap {
    padding: 0 15px;
    @include for-desktop-up() {
      padding: 40px;
    }
  }

  .container-fluid {
    padding: 0 50px;
  }

  .stockholder-img img {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    height: 25em;
    width: 20em;
    object-fit: cover;
  }

  .stockholder-member,
  .stockholder-img {
    position: relative;
    overflow: hidden;
  }

  .stockholder-title {
    margin: 30px 0 7px;
  }

  .overlay {
    background-color: rgba(20, 20, 20, 0.7);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .stockholder-details {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 0;
    padding: 5%;
    overflow: hidden;
    width: 100%;
    z-index: 2;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    p {
      font-size: 0.8em;
    }
  }

  .stockholder-details p {
    color: #fff;
  }

  .stockholder-img:hover .stockholder-details {
    opacity: 1;
    margin-top: -80px;
  }

  .stockholder-img:hover .overlay {
    opacity: 1;
  }

  .socials a {
    display: inline-block;
    width: 37px;
    height: 37px;
    background-color: transparent;
  }

  .socials i {
    line-height: 37px;
    color: #616161;
    font-size: 14px;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    text-align: center;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }

  .stockholder-details .socials i {
    color: #fff;
  }

  .stockholder-details .socials a {
    color: #fff;
  }

  .socials a:hover i {
    color: #fff;
    background-color: #007bff;
  }

  .socials a:hover svg {
    color: #fff;
    background-color: #007bff;
  }
}

table {
  ol {
    padding-inline-start: 1rem;

    li {
      padding-left: 0.8rem;
    }
  }
}

footer {
  position: relative;

  ul {
    list-style-type: none;
    padding-left: 0;
    line-height: 35px;

    @include for-phone-only {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
  }

  a {
    color: $sub-white;
  }

  a:hover {
    color: #fff;
    text-decoration: underline;
  }

  .disclaimer {
    ol {
      padding-left: 16px;

      li {
        padding-left: 16px;
        @include for-phone-only() {
          padding-left: 4px;
        }
      }
    }
  }

  .bg-copyright {
    // background-image: linear-gradient(to right, $top-red, $red);
    background-color: $light-black;
    bottom: 0;
    width: 100%;
  }

  .alert {
    border-radius: 0%;
    background-color: #ebeef0;
    color: black;
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.icon-footer {
  margin-right: 8px;
}

.in-page-link {
  @media only screen and (min-width: 768px) {
    .icon {
      font-size: 1.8rem;
      line-height: 1.8;
      top: 3px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
    }

    .text {
      font-size: 1.2rem;
      color: black;
    }
  }

  .content {
    a {
      text-decoration: none;
    }

    transition: all 0.2s linear;

    &:hover {
      transform: translateX(20px);
      transition: all 0.2s linear;
    }
  }
}

.modal__custom-register {
  .modal-dialog {
    max-width: 600px;
    .modal-content {
      border-radius: 0px;
    }
    .modal-body {
      padding: 0px;
      .row {
        margin: 0px;
      }
    }
  }
  .form-holder {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    background-color: #152733;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .form-holder .form-content {
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .form-content .form-items {
    padding: 40px;
    display: inline-block;
    width: 100%;
    text-align: left;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .form-content h3 {
    color: #fff;
    text-align: left;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .form-content h3.form-title {
    margin-bottom: 30px;
  }

  .form-content p {
    color: #fff;
    text-align: left;
    font-size: 17px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 30px;
  }

  .form-content label,
  .was-validated .form-check-input:invalid ~ .form-check-label,
  .was-validated .form-check-input:valid ~ .form-check-label {
    color: #fff;
  }

  .form-content input[type='text'],
  .form-content input[type='password'],
  .form-content input[type='email'],
  .form-content select {
    padding: 9px 20px;
    text-align: left;
    border: 0;
    outline: 0;
    border-radius: 0px;
    background-color: #fff;
    font-size: 15px;
    font-weight: 300;
    color: #8d8d8d;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .form-content .input-group {
    .input-group-text {
      border-radius: 0px;
    }
    body {
      background: #222;
    }

    .background {
      background-color: $dark-grey;
      color: white;
    }
    .whatsapp-ico {
      width: 42px;
      height: inherit;
      background-color: $dark-grey;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' height='24px' width='24px' fill='%23ffffff'><path fill-rule='evenodd' d='M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z'/></svg>");
    }
    .password-ico {
      width: 42px;
      height: inherit;
      background-color: $dark-grey;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' stroke-width='1.5' viewBox='0 0 24 24' fill='%233e505d'><path fill-rule='evenodd' d='M21 13V8a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h7m2.5 2.5 2 2 4-4M12 11.01l.01-.011m3.99.011.01-.011M8 11.01l.01-.011' stroke='white' stroke-linecap='round' stroke-linejoin='round'/></svg>");
    }
  }

  .btn-primary {
    background-color: #6c757d;
    outline: none;
    border: 0px;
    box-shadow: none;
  }

  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active {
    background-color: #495056;
    outline: none !important;
    border: none !important;
    box-shadow: none;
  }

  .form-content textarea {
    position: static !important;
    width: 100%;
    padding: 8px 20px;
    border-radius: 6px;
    text-align: left;
    background-color: #fff;
    border: 0;
    font-size: 15px;
    font-weight: 300;
    color: #8d8d8d;
    outline: none;
    resize: none;
    height: 120px;
    -webkit-transition: none;
    transition: none;
    margin-bottom: 14px;
  }

  .form-content textarea:hover,
  .form-content textarea:focus {
    border: 0;
    background-color: #ebeff8;
    color: #8d8d8d;
  }

  .mv-up {
    margin-top: -9px !important;
    margin-bottom: 8px !important;
  }

  .invalid-feedback {
    color: #ff606e;
  }

  .valid-feedback {
    color: #2acc80;
  }
}

.accordion__custom-model {
  .accordion-button::after {
    background-size: 2rem;
    background-size: 2rem;
    width: 30px;
    height: 24px;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23da0011'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    transform: rotate(-180deg);
  }
  .accordion-header > button:hover {
    background: #ebeef0;
    text-decoration: none;
    color: #333;
  }
  .accordion-item {
    border: none;
    border-top: 1px solid #e5e5e5;
  }
  .accordion-header > button,
  .accordion-collapse,
  .accordion-body {
    @include for-desktop-up {
      padding-left: calc((100vw - 1170px) / 2 + (1170px / 3) + 10px);
      padding-right: calc((100vw - 1170px) / 2 + (1170px / 3) + 10px);
      margin-left: calc((100vw - 1170px) / -2 - (1170px / 3) - 10px);
      margin-right: calc((100vw - 1170px) / -2 - (1170px / 3) - 10px);
      width: -webkit-fill-available;
    }
  }
  .accordion-button:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  .accordion-header > button {
    height: 4em;
    font-size: 1.5rem;
  }
  .accordion-body {
    border: none;
    font-size: 1.5rem;
    font-weight: lighter;
    padding-top: 35px;
    padding-bottom: 35px;
    background: #f5f6f7;
  }
}

.table > thead > tr > th {
  @include for-desktop-up {
    font-size: 18px;
    font-size: 1.1rem;
    padding: 18px 30px 19px 0;
    font-weight: 400;
    background: #ebeef0;
  }
}

.table thead th {
  @include for-desktop-up {
    max-width: 150px;
    border: 0;
  }
}

.table > thead > tr > th {
  padding: 8px;
  line-height: 2.5;
}

.table {
  &.large {
    @include for-desktop-up() {
      font-size: 22px;
      font-size: 1.4rem;
    }
  }
}

.table {
  td:nth-child(1) {
    border-left: none !important;
  }

  thead > tr > th:nth-child(2) {
    border-left: none !important;
  }

  thead > tr:nth-child(2) > th {
    background-color: #f5f6f7;
  }

  thead > tr:first-child > th {
    border-left: 1px solid #e2e2e2;
  }

  thead > tr:first-child > th:first-child {
    border-left: none !important;
  }

  thead > tr:nth-child(2) > th:nth-of-type(odd) {
    border-left: 1px solid #e2e2e2;
  }

  thead > tr:nth-child(2) > th:first-child {
    border-left: none !important;
  }

  tbody > tr > td:nth-of-type(odd) {
    border-left: 1px solid #e2e2e2;
  }
}

.form-signin {
  max-width: 380px;
  padding: 15px 35px 45px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .checkbox {
    font-weight: normal;
  }

  .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;

    &:focus {
      z-index: 2;
    }
  }

  .password-one {
    margin-bottom: -1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .password-two {
    margin-bottom: 40px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.form-register-sementara {
  .field {
    margin-bottom: 24px;

    input {
      font-size: 24px;
      height: 64px;
      width: 100%;
    }

    .iti {
      display: inherit;
    }
  }

  .intl-tel-input {
    width: 100%;
  }
}

.become-partner--page {
  input {
    outline: 0;
    border-width: 0 0 1px;
    border-color: #212121;
    font-size: 2.8em;
    font-weight: 200;
    width: 100%;
    margin-bottom: 40px;
    @include for-phone-only() {
      font-size: 1.8em;
    }
  }

  input:focus {
    border-color: #000000;
  }

  .banking-route__go-button {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 50px;
    margin-top: 40px;
    width: 100%;
    line-height: 2.43;
    background: #da0011;
    color: #fff;
    border: 0;
    border-radius: 0.25rem;

    @include for-desktop-up() {
      width: 180px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @include for-phone-only() {
      margin-top: 0;
      margin-bottom: 0;
      max-width: 200px;
      font-size: 1.2em;
      margin: 0 auto;
    }
  }

  .field-partnership {
    .iti__selected-dial-code {
      font-size: 3.2rem;
      font-weight: 200;
      @include for-phone-only() {
        font-size: 1.8em;
      }
    }

    .iti--separate-dial-code .iti__selected-flag {
      background-color: transparent;
    }
  }

  .other-people {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
  }

  a,
  mark {
    color: #000;
  }

  a:link {
    color: #000;
    background-color: transparent;
    text-decoration: none;
  }

  a:visited {
    color: #000;
    background-color: transparent;
    text-decoration: none;
  }

  a:hover {
    color: #000;
    background-color: transparent;
    text-decoration: none;
  }

  a:active {
    color: #000;
    background-color: transparent;
    text-decoration: none;
  }

  .follow-icon--linkedin {
    background: #007cb9;
    height: 1.8rem;
    svg {
      fill: #007cb9;
    }
  }

  .contact-image-left-teaser__inner {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 1170px;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    @include for-phone-only() {
      display: block;
    }
  }

  .contact-image-left-teaser__image {
    margin: 0 50px 0 0;
    max-width: 222px;
    opacity: 1;
    width: auto;
    @include for-phone-only() {
      margin: 0;
      max-width: 100%;
    }

    img {
      border: 0;
      vertical-align: middle;
    }
  }

  .contact-image-left-teaser__text::before {
    @include for-tablet-portrait-up() {
      background: #000;
      bottom: 50px;
      content: '';
      position: absolute;
      top: 50px;
      width: 1px;
    }
  }

  .contact-image-left-teaser__header {
    margin-bottom: 15px;
    margin-left: 50px;
    font-size: 35px;
    font-size: 3.5rem;
    font-size: 23px;
    font-size: 2.3rem;
    font-weight: 300;
    line-height: 1.11;
    margin: -3px 0 9px;
  }

  .contact-image-left-teaser__body:last-child {
    margin-bottom: -5px;
  }

  .contact-image-left-teaser__body {
    line-height: 1.67;
  }

  .contact-image-left-teaser__body,
  .contact-image-left-teaser__header {
    margin-left: 50px;
    @include for-phone-only() {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    line-height: 35px;
  }
}

/* MARKETING CONTENT
-------------------------------------------------- */
/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 300;
}
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.marketing {
  h2 {
    font-weight: 300;
    @include for-phone-only() {
      font-weight: 500;
    }
  }
}

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
  @include for-phone-only() {
    margin: 2rem 0;
  }
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  letter-spacing: -0.05rem;
}

/* PBB Big Select Box
------------------------- */
.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  font-size: 2.8em;
  font-weight: 200;
  margin-bottom: 40px;
  z-index: 1;
  border-width: 0 0 1px;
  border-color: #212121;
  border-style: solid;
  @include for-phone-only() {
    font-size: 1.8em;
  }

  &__current {
    position: relative;
    cursor: pointer;
    outline: none;

    &:focus {
      & + .select-box__list {
        opacity: 1;

        // We have to set "animation-name: none;" to make the list visible (read below how it works)

        animation-name: none;

        .select-box__option {
          cursor: pointer;
        }
      }

      .select-box__icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
    width: 32px;
    // transition: 0.7s ease;

    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
  }

  &__value {
    display: flex;
  }

  &__input {
    display: none;

    &:checked + .select-box__input-text {
      display: block;
    }
  }

  &__input-text {
    display: none;
    width: 100%;
    margin: 0;
    background-color: #fff;
  }

  &__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    opacity: 0;

    // We need to use animation with delay.
    // Otherwise the click event will not have time to run on label, because this element disapears immediately when .select-box__current element loses the focus.
    // This delay will not be noticed because we set "opacity" to "0".
    // We also use "animation-fill-mode: forwards" to make the list stay hidden.

    animation-name: HideList;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start;
    box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
    background-color: #fff;
  }

  &__option {
    display: block;
    padding: 15px;
    background-color: #fff;

    &:hover,
    &:focus {
      color: #546c84;
      background-color: #fbfbfb;
    }
  }
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

.select-box__value {
  .ivoji-panel-open {
    &.pull-right {
      line-height: 3;
    }
  }
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.capsule-green {
  background-color: #76ff03;
  border: none;
  color: black;
  padding: 4px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 16px;
  font-size: 12px;
  font-weight: bold;
}

.capsule-red {
  background-color: $red;
  border: none;
  color: white;
  padding: 4px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 16px;
  font-size: 12px;
  font-weight: bold;
}

#js-map-head-office {
  width: 100%;
  height: 20vh;
}

#js-map-klaten-office {
  width: 100%;
  height: 20vh;
}

.font-weight-bold {
  font-weight: 600;
}

li > span {
  font-weight: 300;
  &.font-weight-bold {
    font-weight: 600;
  }
}

.selection-content {
  img {
    max-width: 100%;
    display: block;
    vertical-align: middle;
  }
  .container {
    max-width: 99vw;
    margin: 15px auto;
    padding: 0 15px;
  }

  .top-text-wrapper {
    margin: 20px 0 30px 0;
  }
  .top-text-wrapper h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .top-text-wrapper code {
    font-size: 0.85em;
    background: linear-gradient(90deg, #fce3ec, #ffe8cc);
    color: #ff2200;
    padding: 0.1rem 0.3rem 0.2rem;
    border-radius: 0.2rem;
  }
  .tab-section-wrapper {
    padding: 30px 0;
  }

  .grid-wrapper {
    display: grid;
    grid-gap: 30px;
    place-items: center;
    place-content: center;
  }
  .grid-col-auto {
    // grid-template-columns: repeat(auto-fill, minmax(280px, 0.1fr));
    grid-auto-flow: column;
    grid-template-rows: auto;
  }

  /* ******************* Main Styeles : Radio Card */

  $primary-color: $sub-green;
  $transition: 200ms linear;

  label.radio-card {
    cursor: pointer;

    .card-content-wrapper {
      background: #fff;
      border-radius: 5px;
      max-width: 280px;
      min-height: 330px;
      padding: 15px;
      display: grid;
      box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
      transition: $transition;
    }

    .check-icon {
      width: 20px;
      height: 20px;
      display: inline-block;
      border: solid 2px #e3e3e3;
      border-radius: 50%;
      transition: $transition;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        inset: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: center center;
        transform: scale(1.6);
        transition: $transition;
        opacity: 0;
      }
    }

    input[type='radio'] {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      &:checked {
        + .card-content-wrapper {
          box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px $primary-color;

          .check-icon {
            background: $primary-color;
            border-color: $primary-color;
            transform: scale(1.2);
            &:before {
              transform: scale(1);
              opacity: 1;
            }
          }
        }
      }

      &:focus {
        + .card-content-wrapper {
          .check-icon {
            box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
            border-color: $green;
          }
        }
      }
    }

    .card-content {
      img {
        margin-bottom: 10px;
      }
      h4 {
        font-size: 16px;
        letter-spacing: -0.24px;
        text-align: center;
        color: #1f2949;
        margin-bottom: 10px;
      }

      h5 {
        font-size: 14px;
        line-height: 1.4;
        text-align: center;
        color: #686d73;
      }
    }
  }
}
.otp-content {
  .height-100 {
    height: 40vh;
  }
  .card {
    border: none;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include for-desktop-up() {
      width: 600px;
    }
  }

  .card h6 {
    color: red;
    font-size: 20px;
  }

  .inputs input {
    width: 40px;
    height: 40px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  .form-control:focus {
    box-shadow: none;
    border: 2px solid red;
  }

  .validate {
    border-radius: 0px;
    height: 40px;
    background-color: red;
    border: 1px solid red;
    width: 160px;
  }
}

.pin-input-type {
  input#pin-register,
  input#pin-login {
    position: relative;
    font-size: 16px;
    z-index: 2;
    // border: none;
    // background: transparent;
    letter-spacing: 25.6px;
    font-family: Courier;
    @include for-desktop-up() {
      width: 300px;
    }
  }
}

.text-left {
  text-align: left !important;
}
