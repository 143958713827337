//---------------------------------------------
//
//  Mixin: Google Fonts
//
//    1. Google's names for font files
//    2. Loading a single font
//    3. Loading multiple fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Google's names for font files
//---------------------------------------------

$fontWeightNames: (
  100: 'Thin',
  200: 'ExtraLight',
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'SemiBold',
  700: 'Bold'
);

//---------------------------------------------
// 2. Loading a single font
//---------------------------------------------

@mixin googleFont($font) {
  $fontFamily: map-get($font, 'name');
  $folderName: map-get($font, 'folder');
  $fileName: map-get($font, 'files');
  $weights: map-get($font, 'weights');
  @each $weight in $weights {
    $weightName: map-get($fontWeightNames, $weight);
    @font-face {
      font-family: '#{$fontFamily}';
      font-weight: $weight;
      font-display: swap;
      src: url('../assets/fonts/#{$folderName}/#{$fileName}-#{$weightName}.eot'); /* IE 9 - 11 */
      src: url('../assets/fonts/#{$folderName}/#{$fileName}-#{$weightName}.eot?#iefix') format('embedded-opentype'),
        /* IE 9 - 11 */ url('../assets/fonts/#{$folderName}/#{$fileName}-#{$weightName}.woff2') format('woff2'),
        url('../assets/fonts/#{$folderName}/#{$fileName}-#{$weightName}.woff') format('woff');
    }
  }
}

//---------------------------------------------
// 3. Loading multiple fonts
//---------------------------------------------

@mixin googleFonts($fonts) {
  @each $font in $fonts {
    @include googleFont($font);
  }
}
