//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import 'variables';
@import 'bootstrap';
@import 'fonts';

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
  font-family: 'UniversNext';
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import 'components/slider';
